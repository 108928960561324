<template>
  <div v-if="fileUrl">
    <v-row>
      <v-col cols="1">
        <div class="text-h7 font-weight-bold mx-4">
          {{ fileLabel }}
        </div>
      </v-col>
      <v-col cols="9">
        <div class="text-h7 font-weight-light">
          <a @click="downLoadFile(fileType)">
            {{ getFileName(fileUrl) }}
          </a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  
  name: "DownloadFile",
  props: ["fileUrl", "fileType", "fileLabel" ,"index"],
  methods: {
    getFileName(s3Name) {
      let splitArray = s3Name.split("/");
      splitArray = splitArray[splitArray.length - 1];
      splitArray = splitArray.split("_");
      splitArray = splitArray[splitArray.length - 1];
      return splitArray;
    },
    downLoadFile(type) {
      console.log('index  ---' ,this.index);
      this.$store.dispatch("trainerProfile/downloadAFile",{type:type,index:this.index ,fileUrl:this.fileUrl});
    }
  }
};
</script>

<style></style>
