<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="1200px">
      <template v-slot:activator="{ on }">
        <v-icon v-if="refrenceID" color="primary" v-on="on"   @click="getRefrence">
          border_color
        </v-icon>
        <v-icon color="primary" v-on="on" large v-else>
          add
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <span class="text-h5 font-weight-bold mx-3" v-if="refrenceID"
            > Update the reference</span
          >
            <span class="text-h5 font-weight-bold mx-3" v-else
            >Add a new references</span
          >
            </v-col>
          <v-col cols="1">
            <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
          </v-col>
          <v-col cols="1">
             <v-icon
              color="green"
              @click="saveReference"
             v-if="!refrenceID"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateTheRefrence" dark large dense>
              done
            </v-icon>

          </v-col>
          </v-row>
          
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-form v-model="valid" ref="createReferenceFrom">
          <v-container>
            <v-row v-if="skillNames.length>0">
              <div class="text-h6 font-weight-bold mx-2">
                Topic of training <span class="red--text">*</span>
              </div>
            </v-row>
            <v-row v-if="skillNames.length>0">
              <v-select
                placeholder="Type here . . . . "
                outlined
                solo
                :items="skillNames"
                v-model="reference.topicOfTraining"
              ></v-select>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-2">
                Target Audience of the training
              </div>
            </v-row>
            <v-row>
              <v-select
                :items="targetAudiences"
                label=""
                required
                outlined
                v-model="reference.targetAudience"
              ></v-select>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-2">
                Customer Name
              </div>
            </v-row>
            <v-row>
              <v-text-field outlined required v-model="reference.customerName"></v-text-field>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-2">
                Customer poc name
              </div>
            </v-row>
            <v-row>
              <v-text-field outlined required v-model="reference.customberPocName"></v-text-field>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-2">
                Designation of the Customer PoC
              </div>
            </v-row>
            <v-row>
              <v-text-field outlined required v-model="reference.designationCustomberPoc"></v-text-field>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-2">
                Role Of POC
              </div>
            </v-row>
            <v-row>
              <v-select :items="rolesPoc" label="" required outlined v-model="reference.roleOfPoc"></v-select>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-2">
                Contact Detials
              </div>
            </v-row>
            <v-row>
              <v-text-field outlined label="Email Id" v-model="reference.contactDetials.email"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field outlined label="Contact Number" v-model="reference.contactDetials.contactNumber"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field outlined label="LinkedIn Profile Url" v-model="reference.contactDetials.linkedInProfile"></v-text-field>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-2">
                Email/ Doc Attachement
              </div>
            </v-row>
            <v-row>
                <UploadDoc type="REFERENCES_UPLOAD" />
              </v-row>
              <v-row class="my-n7">
              <DownloadFile fileLabel="" :fileUrl="reference.referenceAttachmentUrl" fileType="REFERENCES_UPLOAD"/>
              </v-row>
          </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import { mapState } from 'vuex'
import UploadDoc from "@/components/uploadDoc.vue";
import DownloadFile from "@/components/DownloadFile.vue"
export default {
  name: "Refrences",
  components:{
    UploadDoc,
    DownloadFile
  },
  data: () => ({
    dialog: false,
    valid: false,
    skillNames:[],
    targetAudiences: [
      "Freshers",
      "Analyst",
      "Sr. Analyst",
      "Senior Executive",
      "Executive",
      "Engineer",
      "SDE-1",
      "SDE-2",
      "Assistant Manager",
      "Software Development Engineer (SDE)-3",
      "Associate Manager",
      "Manager",
      "DGM",
      "Technical Manager",
      "Program Manager",
      "Architect",
      "Chief Engineer",
      "Principal Engineer",
      "Sr. Manager",
      "AVP",
      "AGM",
      "GM",
      "Director",
      "Sr. Architect",
      "Sr. Program Manager",
      "Sr. Chief Engineer",
      "Sr. Program Manager",
      "Sr. Director",
      "VP",
      "EVP",
      "SVP",
    ],
    rolesPoc: ["Business manager", "L&D Stream", "Paricipant"]
  }),
  computed:{
      ...mapState('trainerProfile',{
          reference:'reference',
          loading:'loading',
          profile:'profile'
      })
  },
  props:["refrenceID"],
  async  created() {
   let skillOfTrainer =await this.$store.dispatch('trainerProfile/getSkillNames', this.profile.trainerId)
   console.log('skill names inside ',skillOfTrainer );
   if(skillOfTrainer.length>0){
     this.skillNames=skillOfTrainer
   }
  },
  methods: {
      cb(){
          this.dialog=false;
          this.$refs.createReferenceFrom.reset()
      },
      closeForm(){
        this.dialog=false;
        this.$refs.createReferenceFrom.reset()
      },
    saveReference(){
        let upadateRefernce =this.reference;
        upadateRefernce.trainerId=this.profile.trainerId;
        this.$store.dispatch('trainerProfile/createNewReference',{reference:upadateRefernce,cb:this.cb});
    },
    getRefrence(){
      this.$store.dispatch('trainerProfile/getReferenceToEdit',this.refrenceID)
    },
    updateTheRefrence(){
         this.$store.dispatch('trainerProfile/updateRefrence',{updatedRefrence:this.reference,cb:this.cb}) 
    } 
  },
};
</script>

<style></style>
