<template>
  <v-container fluid >
    <div v-if="adminCommentsList.length > 0">
      <v-list
        v-for="(exceptions, index) in adminCommentsList"
        :key="index"
      >
        <v-card flat width="1400px">
          <v-row no-gutters>
            <v-col cols="2">
              <div class="text-h7 font-weight-bold ml-2 primary--text">{{exceptions.userName}}</div>
            </v-col>
            <v-col cols="2">
              <div class="text-h7" v-if="adminCommentsList.length>0">
                {{ new Date(exceptions.dateOfComment).toLocaleTimeString() }} |
                {{ new Date(exceptions.dateOfComment).toLocaleDateString() }}
              </div>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <span class="mx-2">
              {{ exceptions.comments }}
            </span>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card>
      </v-list>
    </div>
    <v-row>
      <v-col cols="12">
        <v-form ref="adminCommentFrom">
          <v-text-field
          v-if="showAddButton"
            label="Add comments...."
            v-model="adminComments.comments"
            outlined
            solo
            v-on:keyup.enter="createAdminComments"
          ></v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
       <v-btn v-if="showAddButton" @click="createAdminComments"  class="mx-3" color="primary" style="background-color:blue" >Post</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "AdminComments",
  props: ["type" ,"showAddButton"],
  data() {
    return {
      
      };
  },
   computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      adminComments: "adminComments",
      profile: "profile",
      adminCommentsList:"adminCommentsList"
    })
  },
  created() {
    console.log('get all admin commnets');
    this.$store.dispatch('trainerProfile/getAllAdminComments',{type:this.type, trainerId:this.profile.trainerId })
  },
  methods: {
    createAdminComments(){
      this.adminComments.type = this.type;
      this.adminComments.trainerId = this.profile.trainerId;
      
      console.log('create admin commnets' , this.adminComments)
      this.$store.dispatch('trainerProfile/createAdminComments',{ adminComment:this.adminComments, cb:null })
    }
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(38, 123, 250, 0.986);
}
</style>
