<template>
  <v-row>
    <v-col cols="4">
      <v-file-input v-if="allowMultiple"
      v-model="files"
    color="deep-purple accent-4"
    counter
    label="File input"
    multiple
    @change="tempUploadMultipleFiles"
    placeholder="Select your files"
    prepend-icon="mdi-paperclip"
    outlined
    :show-size="1000"
  >
    <template v-slot:selection="{ index, text }">
      <v-chip
        v-if="index < 2"
        color="primary"
        dark
        label
        small
      >
        {{ text }}
      </v-chip>

      <span
        v-else-if="index === 2"
        class="text-overline grey--text text--darken-3 mx-2"
      >
        +{{ files.length - 2 }} File(s)
      </span>
    </template>
  </v-file-input>
      <v-file-input v-else
        outlined
        dense
        accept="image/*,.pdf,.doc,.docx"
        label="File input"
        @change="tmpUploadFile"
      ></v-file-input>
      <!-- <span class="red--text font-weight-light" v-if="this.filesToSend==null">Select some files to upload</span> -->
    </v-col>
    <v-col>
      <v-btn v-if="allowMultiple"
        @click="uploadMultipleFiles"
        class="mx-3"
        color="primary"
        slot="activator"
        style="background-color:blue"
       
        ><v-icon>
          file_upload
          </v-icon></v-btn
      >
      <v-btn
      v-else
        @click="uploadDocumnet"
        class="mx-3"
        color="primary"
        slot="activator"
        style="background-color:blue"
        
        ><v-icon>
          file_upload
          </v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import utils from '@/utils/utilities'
import { mapState } from 'vuex';
export default {
    name:"UploadFileComponent",
    props:["type","allowMultiple"],
    computed:{
        ...mapState('trainerProfile',{
      loading: "loading",
      profile: "profile"
    })
    },
    data() {
      return {
        files:[]
      }
    },
    created(){
      console.log('multiple upload ' ,this.allowMultiple);
    },
    methods:{
    tmpUploadFile(file) {
      console.log('file to upload--',file);
      if (file.size === 0) {
        this.fileToSend = null
        return
      }
      let filename = file.name
      if (!utils.getValidMediaType('trainerDocument', filename)) {
        this.documentUploadSource = ''
        this.fileToSend = null
      } else {
        this.fileToSend = file
        let URL = window.URL || window.webkitURL
        console.log('url ----',URL);
        this.documentUploadSource = URL.createObjectURL(this.fileToSend)
        console.log('document upload sourse',this.documentUploadSource);
      }
    },
    tempUploadMultipleFiles(files){
      console.log('files ' , files);
      if (files.size === 0) {
        this.filesToSend = null
        return
      }
      this.filesToSend =[]
      for(let i=0;i<files.length;i++){
        let fileName=files[i].name
        if (!utils.getValidMediaType('trainerDocument',fileName)) {
        this.documentUploadSource = ''
        this.filesToSend = null
      } else {
        this.filesToSend[i] = files[i]
        let URL = window.URL || window.webkitURL
        console.log('url ----',URL);
        this.documentUploadSource = URL.createObjectURL(this.filesToSend[i])
        console.log('document upload sourse',this.documentUploadSource);
      }
      }
    },
    uploadMultipleFiles(){
      console.log('this. mutiple filees' , this.files);
        console.log('files to send ' , this.filesToSend);
        for(let i=0;i<this.filesToSend.length;i++){
          this.$store.dispatch('trainerProfile/uploadFile', {data: this.filesToSend[i],
       trainerId:this.profile.trainerId , 
       cb: '',
       type:this.type
       }) 
      }
    },
    uploadDocumnet(){
      console.log('type of documnet ', this.type);
      console.log('file to send ' , this.fileToSend);
      if(this.fileToSend==null){
        return;
      }
       this.$store.dispatch('trainerProfile/uploadFile', {data: this.fileToSend,
       trainerId:this.profile.trainerId , 
       cb: '',
       type:this.type
       })
    }
    }
};
</script>

<style></style>
