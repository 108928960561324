export default {
  getValidMediaType (mediaType, url) {
    let smallCase = url.toLowerCase()
    let lastIndex = smallCase.lastIndexOf('.')
    if (lastIndex === -1) return false
    let ext = smallCase.substring(lastIndex + 1, url.length)
    let allowedFileExt = []
    if (mediaType === 'ImgPrev') {
      allowedFileExt = ['jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi', 'gif', 'png', 'bmp', 'dib', 'ico', 'png']
    } else if (mediaType === 'VidPrev') {
      allowedFileExt = ['webm ', 'mkv', 'ogg', 'ogv', 'oga', 'ogx', 'ogm', 'spx', 'opus', 'mp4', 'm4a', 'm4p', 'm4b', 'm4r', 'm4v']
    } else if (mediaType === 'AudPrev') {
      allowedFileExt = ['webm ', 'ogg', 'ogv', 'oga', 'ogx', 'ogm', 'spx', 'opus', 'm4a', 'mp3', 'wav', 'wave', 'flac']
    } else if (mediaType === 'DocPrev') {
      allowedFileExt = ['pdf','doc','docx']
    } else if (mediaType == 'trainerDocument') {
      allowedFileExt=['jpg', 'jpeg', 'png','pdf','doc','docx']
    }
    if (allowedFileExt.length > 0) {
      if (allowedFileExt.indexOf(ext) === -1) {
        return false
      } else {
        return true
      }
    } else if (smallCase.includes('.youtube.')) {
      return true
    }
    return false
  },
  getClientNameObjectArray (clientArray) {
    let clientObjectArray = {}
    for (let i = 0; i < clientArray.length; i++) {
      if (clientArray[i].clientName in clientObjectArray) {
        clientObjectArray[clientArray[i].clientName].push(clientArray[i])
      } else {
        clientObjectArray[clientArray[i].clientName] = [clientArray[i]]
      }
    }
    return clientObjectArray
  },
  getClientList (clientDto) {
    for (let i = 0; i < clientDto.trainingList.length; i++) {
      clientDto.trainingList[i].clientName = clientDto.clientName
    }
    return clientDto.trainingList
  }
}
