<template>
<div v-if="referencesList.length>0">
  <v-list  v-for="(refrenceEntity,index) in referencesList" :key="index" color="#ECF0F3"> 
  <v-card flat>
    <v-row >
      <v-col cols="8">
      </v-col>
      <v-col cols="3">
        <!-- <div class="text-h7 font-weight-bold">
          <v-checkbox
            label="Verified Checkbox"
            color="indigo"
            value="indigo"
            hide-details
          ></v-checkbox>
        </div> -->
      </v-col>
      <v-col cols="1">
        <div class="text-h7 font-weight-bold mx-2 my-3">
         <EditRefrenceDailog :refrenceID="refrenceEntity.id"/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n10 primary--text">
          Reference point of contact
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n10 text--primary">
            {{refrenceEntity.customberPocName}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n7 primary--text">
          Reference point of contact desigination
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n7 text--primary">
            {{refrenceEntity.designationCustomberPoc}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n5 primary--text">
          Reference customer name
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n5  text--primary">
            {{refrenceEntity.customerName}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n3  primary--text">
          Reference by email 
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n3  text--primary">
            {{refrenceEntity.contactDetials.email}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1  primary--text">
          Reference by contact number 
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n1 text--primary">
            {{refrenceEntity.contactDetials.contactNumber}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1  primary--text">
          Reference by linkedin url 
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n1 text--primary">
            {{refrenceEntity.contactDetials.linkedInProfile}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n2 primary--text">
          Role of poc 
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n2 text--primary">
            {{refrenceEntity.roleOfPoc}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n2  primary--text">
          Topic of tranining
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n2  text--primary">
            {{refrenceEntity.topicOfTraining}}
        </div>
      </v-col>
    </v-row>
     <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n2  primary--text">
          Target audience
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n2  text--primary">
            {{refrenceEntity.targetAudience}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1 primary--text">
          Reference attachement
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-n15 my-n4  text--primary">
            <DownloadFile fileType="REFERENCES_UPLOAD" fileLabel="" :fileUrl="refrenceEntity.referenceAttachmentUrl" :index="index"/>
        </div>
      </v-col>
    </v-row>
  </v-card>
  </v-list>
</div>
  <!-- <v-card v-else>
    <h1>No Refrence associated with trainer</h1>
  </v-card> -->
</template>

<script>
import { mapState } from 'vuex'
///import Pill from '@/components/ChipComponent/pill.vue'
import EditRefrenceDailog from '@/components/RefrencesDailog/refrencesDailog.vue'
import DownloadFile from '../DownloadFile.vue'
export default {
  name: "ReferenceCard",
  components:{
    //Pill,
    EditRefrenceDailog,
    DownloadFile
  },
data(){
  return{
  } 
},
 computed:{
      ...mapState('trainerProfile',{
          reference:'reference',
          loading:'loading',
          profile:'profile',
          referencesList:'referencesList'
      })
  },
props:['trainerId'],
created() {
    this.$store.dispatch('trainerProfile/getAllRefrences',this.trainerId)
  },
};
</script>

<style></style>
