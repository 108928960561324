<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col col="11">
        <div class="text-h5 font-weight-bold mx-3">
          References
        </div>
      </v-col>
      <v-col cols="1">
        <!-- dailog to add  refrences -->
     <RefrencesDailog/>
      </v-col>
    </v-row>
    <!-- <div :v-for="item in referencesList">
      {{item}}
    </div> -->
     <ReferenceCard :v-if="referencesList.length!=0" :trainerId="profile.trainerId"/>
     <div :class="true?activeClass:nonActive">
             Admin comments
    </div>
    <AdminComments :showAddButton="true" type="REFERENCES"/>
  </v-container>
</template>
<script>
import SecondaryNavigation from "@/components/SecondaryNavigation/SecondaryNavigation.vue";
import AdminComments from "../../../components/AdminComments/AdminComments.vue";
import ReferenceCard from '@/components/RefrenceCard/refrenceCard.vue'
import { mapState } from 'vuex'
import RefrencesDailog from '@/components/RefrencesDailog/refrencesDailog.vue' 
export default {
  name: "Refrences",

  components: {
   AdminComments,
    // MainTitle,
    SecondaryNavigation,
    RefrencesDailog,
    ReferenceCard
  },
   computed:{
      ...mapState('trainerProfile',{
          loading:'loading',
          profile:'profile',
          referencesList:'referencesList'
      })
      },
  data() {
      return {
        source:["Linkedin", "Facebook"],
        activeClass:"text-h6 font-weight-bold mx-3",
      nonActive:"text-h6 primary--text font-weight-bold",  
      }
  },
};
</script>
<style>
</style>
